// @ts-nocheck
import { useState, useEffect } from "react";

type NetworkStatus = "NO_NETWORK" | "STABLE" | "WEAK" | "UNKNOWN";

const useNetworkStatus = (): NetworkStatus => {
  const [networkStatus, setNetworkStatus] = useState<NetworkStatus>("UNKNOWN");

  const updateNetworkStatus = () => {
    if (navigator.onLine) {
      if ("connection" in navigator) {
        const connection =
          navigator.connection ||
          navigator.mozConnection ||
          navigator.webkitConnection;
        if (connection && connection.effectiveType) {
          const effectiveType = connection.effectiveType;

          switch (effectiveType) {
            case "4g":
              setNetworkStatus("STABLE");
              break;
            case "3g":
            case "2g":
            case "slow-2g":
              setNetworkStatus("WEAK");
              break;
            default:
              setNetworkStatus("UNKNOWN");
          }
        } else {
          setNetworkStatus("UNKNOWN");
        }
      } else {
        setNetworkStatus("UNKNOWN");
      }
    } else {
      setNetworkStatus("NO_NETWORK");
    }
  };

  useEffect(() => {
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    if ("connection" in navigator && navigator.connection) {
      // @ts-ignore
      navigator.connection.addEventListener("change", updateNetworkStatus);
    }

    updateNetworkStatus(); // Initial check

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);

      if ("connection" in navigator && navigator.connection) {
        navigator.connection.removeEventListener("change", updateNetworkStatus);
      }
    };
  }, []);

  return networkStatus;
};

export default useNetworkStatus;
export type { NetworkStatus };
