import mixpanel from "mixpanel-browser";
import store from "../../redux/store";
import * as EventNames from "./EventNames";
import packageJson from "../../../package.json";
import { generateUUID } from "../Util";

// Generate a new session ID
let sessionId = generateUUID();

const Mixpanel = {
  track: (name: string, props?: Record<string, unknown>) => {
    const globalProps = getGlobalProperties();
    const payload = { ...globalProps, ...props };

    if (process.env.NODE_ENV !== "production") {
      console.log("MIXPANEL DATA: ", name, payload);
    }

    mixpanel.track(name, payload);
  },
  init: () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      // debug: process.env.NODE_ENV !== 'production'
    });
  },
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  reset: () => {
    mixpanel.reset();
  },
  people: {
    set: (props: Record<string, unknown>) => {
      mixpanel.people.set(props);
    },
  },
};

// Helper function to get global properties from Redux store
const getGlobalProperties = () => {
  const appState = store.getState().app;
  const userDetails = appState.userDetails;

  const data = {
    "JS Version": packageJson.version,
    "Session ID": sessionId,
  };

  if (userDetails) {
    data["Organization Id"] = userDetails.organization_id;
    data["Email Id"] = userDetails.email;
    data["Organization Name"] = userDetails.firm;
  }

  return data;
};

export { EventNames };
export default Mixpanel;
