import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/store";

interface PrivateRouteType {
  Component: any;
}

const PrivateRoute = ({ Component }: PrivateRouteType) => {
  // dont remove this line for now + dont comment out as well
  const token = useSelector((state: RootState) => state.app.token);
  const userOnboard = token && localStorage.getItem("user_onboard");

  return userOnboard ? Component : <Navigate to="/login" />;
};
export default PrivateRoute;
