// useDeviceStatus.ts
import { useState, useEffect } from "react";
import { isExtension, isSmallDevice, isMobile } from "../utils/Util";

interface DeviceStatus {
  isExtension: boolean;
  isSmallDevice: boolean;
  isMobile: boolean;
}

export const useDeviceStatus = (): DeviceStatus => {
  const [deviceStatus, setDeviceStatus] = useState<DeviceStatus>({
    isExtension: isExtension(),
    isSmallDevice: isSmallDevice(),
    isMobile: isMobile(),
  });

  useEffect(() => {
    const handleResize = () => {
      const newDeviceStatus = {
        isExtension: isExtension(),
        isSmallDevice: isSmallDevice(),
        isMobile: isMobile(),
      };

      // Only update the state if there's a change
      if (
        newDeviceStatus.isExtension !== deviceStatus.isExtension ||
        newDeviceStatus.isSmallDevice !== deviceStatus.isSmallDevice ||
        newDeviceStatus.isMobile !== deviceStatus.isMobile
      ) {
        setDeviceStatus(newDeviceStatus);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [deviceStatus]);

  return deviceStatus;
};
