import { getCLS, getFID, getFCP, getLCP, getTTFB } from "web-vitals";
import debounce from "lodash/debounce";
import Mixpanel, { EventNames } from "./mixpanel";

// Object to store Web Vitals metrics
const webVitalsMetrics = {};

// Function to send metrics to Mixpanel
const sendWebVitalsToMixpanel = () => {
  Mixpanel.track(EventNames.WEB_VITALS, { ...webVitalsMetrics });
};

// Debounced function to batch and send metrics
const debouncedSendMetrics = debounce(() => {
  sendWebVitalsToMixpanel();
}, 1000); // Adjust debounce delay as needed

// Function to handle metric collection
const handleMetric = (metric) => {
  webVitalsMetrics[metric.name] = metric.value;
  debouncedSendMetrics();
};

// Function to collect Web Vitals metrics
const collectWebVitals = () => {
  getCLS(handleMetric);
  getFID(handleMetric);
  getFCP(handleMetric);
  getLCP(handleMetric);
  getTTFB(handleMetric);
};

// Initialize Web Vitals collection
const initWebVitals = () => {
  if (document.readyState === "complete") {
    collectWebVitals();
  } else {
    window.addEventListener("load", collectWebVitals);
  }
};

export default initWebVitals;
