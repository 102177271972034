import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ROUTES } from "../api/config";
import { arrayBufferToBase64 } from "../utils/Util";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { toastEmitter } from "../components/toast/toastManager";
import { fetchMeetingDetails } from "../redux/actions/appActions";
import Mixpanel, { EventNames } from "../utils/analytics/mixpanel";
import useNetworkStatus, { NetworkStatus } from "../hooks/useNetworkStatus";
import useWakeLock from "../hooks/useWakeLock";
import useAudio, { AUDIO_PATH } from "../hooks/useAudio";

// to set the initial sample rate for audioContext
const SAMPLE_RATES = [16000, 22050, 32000, 44100, 48000];
var SAMPLE_RATE = 16000;

for (const rate of SAMPLE_RATES) {
  try {
    // @ts-ignore
    const audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: rate });
    if (audioContext.sampleRate === rate) {
      SAMPLE_RATE = rate;
      audioContext.close();
      break;
    }
    audioContext.close();
  } catch (e) {
    // Sample rate not supported
  }
}

type MiliStates =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "RESUME"
  | "FINALIZING_NOTES"
  | "GENERATING_SUMMARY"
  | "GENERATING_FOLLOW_UP"
  | "COMPLETED"
  | null;

const SocketContext = createContext<any>({});

export const useSocket = () => useContext(SocketContext);

function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const SocketProvider = ({ children }) => {
  const isConnectedRef = useRef<boolean>(false);
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const activeMeetingIdRef = useRef(null);
  const socketRef = useRef<WebSocket | null>(null);
  const [miliState, setMiliState] = useState<MiliStates>(null);
  const isRecordingRef = useRef(false);
  const token = useSelector((state: RootState) => state.app.token);
  const dispatch = useDispatch<AppDispatch>();

  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [scriptProcessor, setScriptProcessor] = useState<ScriptProcessorNode | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const networkStatus: NetworkStatus = useNetworkStatus();
  const audioChunksRef = useRef([]);
  const networkStatusRef = useRef(null);
  const networkTimestamp = useRef(null);
  const miliStateRef = useRef<MiliStates>(null);
  const { requestWakeLock, releaseWakeLock } = useWakeLock();
  const { play } = useAudio();


  useEffect(() => {
    miliStateRef.current = miliState;
  }, [miliState]);

  // TODO: fix reconnect at BE + on reconnect disconnects at FE
  // useEffect(() => {
  //   let timeout;
  //   if (socketRef.current && networkStatus === 'NO_NETWORK') {
  //     disconnect();
  //     networkTimestamp.current = Date.now();
  //     timeout = setTimeout(() => {
  //       setMiliState('RESUME');
  //       audioChunksRef.current = [];
  //       stopRecording();
  //     }, 30000); //30sec
  //   }
  //   if (networkStatus === 'STABLE' && audioChunksRef.current?.length) {
  //     const timeSinceDisconnect = Date.now() - networkTimestamp.current;
  //     // if >= 30 seconds, just stop the recording and do not reconnect on your own;
  //     if (timeSinceDisconnect >= 30000) return;
  //     clearTimeout(timeout);

  //     stopRecording();
  //     setTimeout(() => connect(activeMeetingId), 500);
  //   }
  //   networkStatusRef.current = networkStatus;
  // }, [networkStatus]);

  useEffect(() => {
    activeMeetingIdRef.current = activeMeetingId;
  }, [activeMeetingId]);

  useEffect(() => {
    if (miliState === 'IN_PROGRESS') {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }
  }, [miliState]);

  const connect = async (id) => {
    if (networkStatus === 'NO_NETWORK') {
      toastEmitter.show({
        message: "Please check your internet connection",
        severity: "error",
      });
      return;
    }
    const permissionStatus = await navigator.permissions.query({
      name: 'microphone' as PermissionName
    })
    if (permissionStatus.state === 'denied') {
      toastEmitter.show({
        message: "Please give microphone permission to continue",
        severity: "info",
      });
      return;
    }

    setActiveMeetingId(id);

    if (
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      const url = ROUTES.WS_SOCKET(id, token);
      socketRef.current = new WebSocket(url);

      socketRef.current.onopen = () => {
        isConnectedRef.current = true;
        sendCommand("START");
      };

      socketRef.current.onclose = (event) => {
        console.log("WebSocket disconnected", event);

        if (networkStatusRef.current === 'NO_NETWORK') return;

        if (!event.wasClean || ['IN_PROGRESS', 'RESUME'].includes(miliStateRef.current)) {
          const errMsg = event.reason || 'Unable to connect to server';

          toastEmitter.show({
            message: errMsg,
            severity: "error",
          });

          play(AUDIO_PATH.ERROR);

          Mixpanel.track(EventNames.MEETING_ALERT, {
            'Meeting ID': activeMeetingIdRef.current,
            'Alert Type': 'Error',
            "Category": 'Socket',
            'Alert Message': errMsg,
            'Error Code': event.code
          })

          setMiliState('RESUME');
          disconnect();
        }
        stopRecording();
      };

      socketRef.current.onerror = (error: ErrorEvent) => {
        console.error("WebSocket connection error: ", error);

        const errMsg = error.message || 'Socket connection error';

        toastEmitter.show({
          message: errMsg,
          severity: "error",
        });

        play(AUDIO_PATH.ERROR);

        Mixpanel.track(EventNames.MEETING_ALERT, {
          'Meeting ID': activeMeetingIdRef.current,
          'Alert Type': 'Error',
          "Category": 'Socket',
          'Alert Message': errMsg
        })

        setMiliState('RESUME');

        disconnect();
        stopRecording();
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        //disconnect on stop Event
        if (data.type === 'STATUS' && data.status === 'listening') {
          startRecording();
        }

        //disconnect on stop Event
        if (data.type === 'STATUS' && data.status === 'stopped') {
          disconnect();
        }
      };
    } else {
      disconnect();
      setTimeout(() => connect(id), 1500);
    }
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      isConnectedRef.current = false;
      console.log("WebSocket disconnected");
    }
  };

  //STOP|PAUSE|UNPAUSE
  const pauseStop = (type) => {
    if (type === "STOP") {
      setMiliState(socketRef.current ? 'FINALIZING_NOTES' : 'RESUME'); // RESUME for offline case
      stopRecording();
      sendCommand('STOP');
    }
    if (socketRef.current) {
      if (type === "PAUSE") {
        setMiliState('RESUME');
        stopRecording();
      }
      if (type === "UNPAUSE") {
        startRecording();
      }
    }
  };

  const sendAudioChunks = () => {
    if (networkStatusRef.current === 'NO_NETWORK') return;

    if (socketRef.current && audioChunksRef.current.length > 0) {
      while (audioChunksRef.current.length > 0) {
        const chunk = audioChunksRef.current.shift();
        if (chunk) {
          console.log('socketRef.current.send')
          socketRef.current.send(
            JSON.stringify({ type: "AUDIO", audio_data: chunk })
          );
        }
      }
      // If there are more chunks, continue immediately
      sendAudioChunks();
    } else if (isRecordingRef.current) {
      // If no chunks but still recording, check again after 1 second
      setTimeout(sendAudioChunks, 1000);
    }
  };

  async function startRecording() {
    isRecordingRef.current = true;

    // WebView handling
    if ((window as any).startRecording) {
      setMiliState('IN_PROGRESS');
      (window as any).startRecording();
      return;
    }

    try {
      await sleep(1000); // 1 second delay

      var CHANNELS = 1;
      var SAMPLE_WIDTH = 2; // bytes

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: CHANNELS,
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false
        }
      });

      streamRef.current = stream;

      socketRef.current?.send(
        JSON.stringify({ type: "AUDIO_METADATA", audio_metadata: { sample_rate: SAMPLE_RATE, channels: CHANNELS, sample_width: SAMPLE_WIDTH } })
      );

      // sendAudioChunks();

      const newAudioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
      const source = newAudioContext.createMediaStreamSource(stream);
      const newScriptProcessor = newAudioContext.createScriptProcessor(16384, 1, 1);

      source.connect(newScriptProcessor);
      newScriptProcessor.connect(newAudioContext.destination);

      newScriptProcessor.onaudioprocess = (audioProcessingEvent) => {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);

        // Convert Float32Array to Int16Array for more efficient transmission
        const pcmData = new Int16Array(inputData.length);
        for (let i = 0; i < inputData.length; i++) {
          pcmData[i] = Math.max(-32768, Math.min(32767, Math.floor(inputData[i] * 32768)));
        }

        const audioBase64 = arrayBufferToBase64(pcmData.buffer);

        // audioChunksRef.current.push(audioBase64);
        socketRef.current.send(
          JSON.stringify({ type: "AUDIO", audio_data: audioBase64 })
        );
      };

      setAudioContext(newAudioContext);
      setScriptProcessor(newScriptProcessor);

      setMiliState('IN_PROGRESS');
    } catch (error) {
      console.error("Error starting recording:", error);
      setMiliState('RESUME');
      disconnect();
      if (error.message === 'Permission denied') {
        toastEmitter.show({
          message: 'Please give microphone permission to continue',
          severity: "info",
        });
        return;
      }
      toastEmitter.show({
        message: error.message,
        severity: "error",
      });
    }
  }

  function stopRecording() {
    isRecordingRef.current = false;

    // WebView handling
    if ((window as any).stopRecording) {
      (window as any).stopRecording();
      return;
    }

    if (scriptProcessor) {
      scriptProcessor.disconnect();
      setScriptProcessor(null);
    }
    if (audioContext) {
      audioContext.close();
      setAudioContext(null);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  }

  function sendCommand(command) {
    if (!socketRef.current) return;
    socketRef.current.send(
      JSON.stringify({ type: "COMMAND", command: command })
    );
  }

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        setActiveMeetingId(null);
        socketRef.current.close();
      }
    };
  }, []);

  // WebView handling
  useEffect(() => {
    // Define the function and expose it to the window object
    (window as any).onNewRecording = (audioData, isMetaData: boolean = false) => {

      let audioObj;

      if (isMetaData) {
        audioObj = { type: "AUDIO_METADATA", audio_metadata: JSON.parse(audioData) };
      } else {
        audioObj = { type: "AUDIO", audio_data: audioData };
      }

      socketRef.current?.send(
        JSON.stringify(audioObj)
      );
    };

    return () => {
      // Cleanup: remove the function from the window object when the component unmounts
      delete (window as any).onNewRecording;
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        startRecording,
        sendCommand,
        isConnectedRef,
        activeMeetingId,
        pauseStop,
        miliState,
        setMiliState,
        setActiveMeetingId,
        activeMeetingIdRef,
        socketRef
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
