import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function calculateAge(dob: string): number {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If birth date month is greater than current month or
  // if birth date month is equal to current month but birth date day is greater than current day,
  // then decrement age by 1
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function formatDateTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  if (!dateTimeString) return { date: "", time: "" };
  const date = new Date(dateTimeString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return { date: formattedDate, time: formattedTime };
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export const isExtension = () =>
  window.location.protocol === "chrome-extension:";
export const isSmallDevice = () =>
  window.matchMedia("(max-width: 600px)").matches;
export const isMobile = () => isSmallDevice() && !isExtension();

export const injectMicrophonePermissionIframe = () => {
  // const iframe = document.createElement("iframe");
  // iframe.setAttribute("hidden", "hidden");
  // iframe.setAttribute("id", "permissionsIFrame");
  // iframe.setAttribute("allow", "microphone");
  // iframe.src = chrome.runtime.getURL("permission/index.html");
  // document.body.appendChild(iframe);

  navigator.mediaDevices.getUserMedia({ audio: true }).catch((err) => {
    console.log(err);

    chrome.tabs.create({
      url: chrome.runtime.getURL("permission/index.html"),
      active: true,
    });
  });
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateOtp = (otp: string): boolean => {
  const otpRegex = /^\d{1,6}$/;
  return otpRegex.test(otp);
};

export const getMeetingHtmlContent = ({
  meetingDetails,
  notesHtml,
  summaryHtml,
}) => {
  const { date, time } = formatDateTime(meetingDetails.start_time);
  const firmParticipants = meetingDetails.firm_side_participants
    .map(
      (i, index) =>
        (index === 0 ? "" : ", ") + `${i.name} (${i.meeting_role_display})`
    )
    .join("");
  const clients = meetingDetails.client_side_participants
    .map((i) => `${i.name} (client)`)
    .join(", ");

  return `
  <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
    <h2 style="color: #5D00E2;">${meetingDetails.title}</h2>
    <p style="font-size: 14px; margin-bottom: 20px;">
      <strong>Date:</strong> ${date} | ${time}
    </p>
    <p style="font-size: 14px; margin-bottom: 20px;">
      <strong>Attendees:</strong> ${firmParticipants}${
    clients ? ", " + clients : ""
  }
    </p>
    ${
      summaryHtml
        ? `
    <div style="margin-bottom: 20px;">
      <h4 style="color: #5D00E2; margin-bottom: 10px;">Summary</h4>
      <div style="padding: 10px; background-color: #F9F9F9; border-left: 4px solid #5D00E2;">
        ${summaryHtml}
      </div>
    </div>
    `
        : ""
    }
    ${
      notesHtml
        ? `
    <div style="margin-bottom: 20px;">
      <h4 style="color: #5D00E2; margin-bottom: 10px;">Notes</h4>
      <div style="padding: 10px; background-color: #F9F9F9; border-left: 4px solid #5D00E2;">
        ${notesHtml}
      </div>
    </div>
    `
        : ""
    }
  </div>
  `;
};

export function compareVersions(v1, v2) {
  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  for (let i = 0; i < 3; i++) {
    if (v1Parts[i] < v2Parts[i]) {
      return -1; // v1 is less than v2
    }
    if (v1Parts[i] > v2Parts[i]) {
      return 1; // v1 is greater than v2
    }
  }

  return 0; // v1 is equal to v2
}

export const hardReload = () => {
  // Store the current URL without any parameters
  const originalURL = window.location.href.split("?")[0];

  // Append a timestamp to the URL to force a reload
  const newURL = `${originalURL}?timestamp=${new Date().getTime()}`;

  // Change the URL to the one with the timestamp to bypass the cache
  window.location.href = newURL;

  // Use replaceState to clean the URL immediately after reload
  window.history.replaceState(null, "", originalURL);
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
