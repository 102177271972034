import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchUserProfile,
  fetchClientDetails,
  fetchClientList,
  fetchMeetingList,
  fetchClientMeetingList,
  fetchMeetingDetails,
  updateMeetingDetails,
  createMeeting,
  fetchContactList,
  fetchMeetingTypeList,
  fetchAdvisorsList,
  fetchEMoneyDetails,
} from "../actions/appActions";
import { toastEmitter } from "../../components/toast/toastManager";
import history from "../../router/history";
import Mixpanel from "../../utils/analytics/mixpanel";

interface AppState {
  token: string | null;
  clientList: any;
  clientDetails: any;
  userDetails: any;
  isUserDetailsLoading: boolean;
  meetingList: any;
  clientMeetingList: any;
  meetingDetails: any;
  socketOnMsgCount: number;
  addMeetingToggle: boolean;
  contacts: any;
  advisors: any;
  meetingTypes: any;
  eMoneyDetails: any;
}

const initialState: AppState = {
  token: null,
  userDetails: null,
  isUserDetailsLoading: false,
  clientList: {
    data: [],
    isLoading: false,
    error: null,
  },
  clientDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  meetingList: {
    upcoming: [],
    current: [],
    isLoading: true,
    paginationLoading: false,
    error: null,
  },
  clientMeetingList: {
    data: [],
    isLoading: false,
    error: null,
  },
  meetingDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  eMoneyDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  socketOnMsgCount: 0,
  addMeetingToggle: false,
  contacts: [],
  advisors: [],
  meetingTypes: [],
};

function addUserProfileCases(builder) {
  builder
    .addCase(fetchUserProfile.pending, (state) => {
      state.isUserDetailsLoading = true;
    })
    .addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.isUserDetailsLoading = false;
      state.userDetails = action.payload;

      Mixpanel.identify(state.userDetails.user_id);

      if (state.userDetails?.onboarded) {
        localStorage.setItem("user_onboard", "true");
      } else {
        localStorage.removeItem("user_onboard");
      }
    })
    .addCase(fetchUserProfile.rejected, (state, action) => {
      state.isUserDetailsLoading = false;
    });
}

function addClientListCases(builder) {
  builder
    .addCase(fetchClientList.pending, (state) => {
      state.clientList.isLoading = true;
      state.clientList.error = null;
    })
    .addCase(fetchClientList.fulfilled, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error = null;
      state.clientList.data = action.payload.clients;
    })
    .addCase(fetchClientList.rejected, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error =
        action.error.message || "Error occurred while fetching client list.";
    });
}

function addClientDetailsCases(builder) {
  builder
    .addCase(fetchClientDetails.pending, (state) => {
      state.clientDetails.isLoading = true;
      state.clientDetails.error = null;
    })
    .addCase(fetchClientDetails.fulfilled, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.data = action.payload;
    })
    .addCase(fetchClientDetails.rejected, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.error = action.error.message;
    });
}

function addMeetingListCases(builder) {
  builder
    .addCase(fetchMeetingList.pending, (state, action) => {
      if (action.meta.arg === 0) {
        state.meetingList.isLoading = true;
      } else {
        state.meetingList.paginationLoading = true;
      }
      state.meetingList.error = null;
    })
    .addCase(fetchMeetingList.fulfilled, (state, action) => {
      state.meetingList.paginationLoading = false;
      state.meetingList.isLoading = false;
      if (action.meta.arg === 0) {
        state.meetingList.upcoming = action.payload.data;
      } else {
        state.meetingList.upcoming = [
          ...state.meetingList.upcoming,
          ...action.payload.data,
        ];
      }
    })
    .addCase(fetchMeetingList.rejected, (state, action) => {
      state.meetingList.paginationLoading = false;
      state.meetingList.isLoading = false;
      state.meetingList.error = action.error.message;
    });
}

function addClientMeetingListCases(builder) {
  builder
    .addCase(fetchClientMeetingList.pending, (state) => {
      state.clientMeetingList.isLoading = true;
      state.clientMeetingList.error = null;
      state.clientMeetingList.data = [];
    })
    .addCase(fetchClientMeetingList.fulfilled, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.data = action.payload;
    })
    .addCase(fetchClientMeetingList.rejected, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.error = action.error.message;
    });
}

function addMeetingDetailsCases(builder) {
  builder
    .addCase(fetchMeetingDetails.pending, (state, action) => {
      if (!action.meta.arg.silentRefresh) {
        state.meetingDetails.isLoading = true;
      }
      state.meetingDetails.error = null;
      // state.meetingDetails.data = null;
    })
    .addCase(fetchMeetingDetails.fulfilled, (state, action) => {
      state.meetingDetails.isLoading = false;
      state.meetingDetails.data = action.payload;
      if (action.meta.arg.silentRefresh) {
        state.socketOnMsgCount = state.socketOnMsgCount + 1;
      }
    })
    .addCase(fetchMeetingDetails.rejected, (state, action) => {
      state.meetingDetails.isLoading = false;
      state.meetingDetails.error = action.error.message;
    });
}

function addUpdateMeetingDetailsCases(builder) {
  builder
    .addCase(updateMeetingDetails.pending, (state) => {})
    .addCase(updateMeetingDetails.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting updated successfully",
        severity: "success",
      });
    })
    .addCase(updateMeetingDetails.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

function addCreateMeetingCases(builder) {
  builder
    .addCase(createMeeting.pending, (state) => {})
    .addCase(createMeeting.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting created successfully",
        severity: "success",
      });
      const data = action.payload;
      if (data) {
        history.push(`/meeting-details/${data.id}`);
      }
    })
    .addCase(createMeeting.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addContactListCases(builder) {
  builder
    .addCase(fetchContactList.pending, (state) => {})
    .addCase(fetchContactList.fulfilled, (state, action) => {
      state.contacts = action.payload.clients.reduce((acc, contact) => {
        if (
          !acc.some((existingContact) => existingContact.name === contact.name)
        ) {
          acc.push(contact);
        }
        return acc;
      }, []);
    })
    .addCase(fetchContactList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addAdvisorsListCases(builder) {
  builder
    .addCase(fetchAdvisorsList.pending, (state) => {})
    .addCase(fetchAdvisorsList.fulfilled, (state, action) => {
      state.advisors = action.payload.advisors.reduce((acc, contact) => {
        if (
          !acc.some((existingContact) => existingContact.name === contact.name)
        ) {
          acc.push(contact);
        }
        return acc;
      }, []);
    })
    .addCase(fetchAdvisorsList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addMeetingTypeListCases(builder) {
  builder
    .addCase(fetchMeetingTypeList.pending, (state) => {})
    .addCase(fetchMeetingTypeList.fulfilled, (state, action) => {
      state.meetingTypes = action.payload.meeting_types;
    })
    .addCase(fetchMeetingTypeList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

function addEMoneyDetailsCases(builder) {
  builder
    .addCase(fetchEMoneyDetails.pending, (state) => {
      state.eMoneyDetails.isLoading = true;
    })
    .addCase(fetchEMoneyDetails.fulfilled, (state, action) => {
      state.eMoneyDetails.data = action.payload;
    })
    .addCase(fetchEMoneyDetails.rejected, (state, action) => {
      state.eMoneyDetails.isLoading = false;
      toastEmitter.show({
        message: action.error.message || "Something went wrong",
        severity: "error",
      });
    });
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload); // Save token to localStorage
      } else {
        state.userDetails = null;
        localStorage.clear();
      }
    },
    incrementSocketOnMsgCount(state) {
      state.socketOnMsgCount = state.socketOnMsgCount + 1;
    },
    setMeetingDetails(state, action) {
      state.meetingDetails.data = {
        ...state.meetingDetails.data,
        ...action.payload,
      };
    },
    clearMeetingDetails(state) {
      state.meetingDetails = initialState.meetingDetails;
    },
    setAddMeetingToggle(state, action) {
      state.addMeetingToggle = action.payload;
    },
    clearEMoneyDetails(state) {
      state.eMoneyDetails = initialState.eMoneyDetails;
    },
  },
  extraReducers: (builder) => {
    addUserProfileCases(builder);
    addClientListCases(builder);
    addClientDetailsCases(builder);
    addMeetingListCases(builder);
    addClientMeetingListCases(builder);
    addMeetingDetailsCases(builder);
    addUpdateMeetingDetailsCases(builder);
    addCreateMeetingCases(builder);
    addContactListCases(builder);
    addAdvisorsListCases(builder);
    addMeetingTypeListCases(builder);
    addEMoneyDetailsCases(builder);
  },
});

export const {
  setToken,
  setMeetingDetails,
  incrementSocketOnMsgCount,
  setAddMeetingToggle,
  clearMeetingDetails,
  clearEMoneyDetails,
} = appSlice.actions;

export const selectToken = (state: RootState) => state.app.token;

export default appSlice.reducer;
