export const SCREEN_LOAD = "Screen Load";
export const LOGIN_ATTEMPT = "Login Attempt";
export const OTP_ENTERED = "OTP Entered";
export const AUTHENTICATION_RESULT = "Authentication Result";
export const CARD_CLICKED = "Card Clicked";
export const BUTTON_CLICKED = "Button Clicked";
export const MEETING_CONTROLS_CLICKED = "Meeting Controls Clicked";
export const MODAL_OPENED = "Modal Opened";
export const MODAL_CLOSED = "Modal Closed";
export const MEETING_ALERT = "Meeting Alert";
export const MEETING_ALERT_CLEARED = "Meeting Alert Cleared";
export const NOTES_EDITED = "Notes Edited";
export const SNACKBAR_APPEARED = "Snackbar Appeared";
export const NOTES_REFRESHED = "Notes Refreshed";
export const SUMMARY_GENERATED = "Summary Generated";
export const SCREEN_NAVIGATION = "Screen Navigation";
export const USER_LOGOUT = "User Logout";
export const NOTIFICATION_SENT = "Notification Sent";
export const NOTIFICATION_INTERACTION = "Notification Interaction";
export const API_CALL = "API Call";
export const WEB_VITALS = "Web Vitals";
export const APP_RELOAD = "App Reload";
