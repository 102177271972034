import { lazy, useEffect } from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import history, { resetHistory } from "./history";
import Layout from "./layout";
import PrivateRoute from "./privateRoute";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import Loader from "../components/loader";
import { get } from "../api";
import { ROUTES } from "../api/config";
import packageJson from '../../package.json';
import { compareVersions, hardReload } from "../utils/Util";
import { useSocket } from "../context/SocketContext";
import Mixpanel, { EventNames } from "../utils/analytics/mixpanel";
import ScrollToTop from "../components/scrollToTop";

const Login = lazy(
  /* webpackChunkName: 'Login' */() => import("../pages/login")
);
const UpcomingMeetings = lazy(
  /* webpackChunkName: 'UpcomingMeetings' */() =>
    import("../pages/upcomingMeetings")
);
const AllClients = lazy(
  /* webpackChunkName: 'AllClients' */() => import("../pages/allClients")
);
const ClientAllMeetings = lazy(
  /* webpackChunkName: 'ClientAllMeetings' */() =>
    import("../pages/clientAllMeetings")
);
const MeetingDetails = lazy(
  /* webpackChunkName: 'MeetingDetails' */() =>
    import("../pages/meetingDetails")
);

const Settings = lazy(
  /* webpackChunkName: 'settings' */() => import("../pages/settings")
);

const Router = () => {
  const isUserDetailsLoading = useSelector((state: RootState) => state.app.isUserDetailsLoading);
  const { socketRef } = useSocket();

  useEffect(() => {
    resetHistory();
  }, [])

  // fetch latest js from server - mainly for windows/iOS webViews
  const fetchAppVersion = async () => {
    try {

      if (socketRef.current?.readyState === WebSocket.OPEN) return;

      const lastVersionCheck = localStorage.getItem('last-version-check');
      const lastAppReload = localStorage.getItem('last-app-reload');

      // Check if the last version check was performed recently - 6hrs
      if (lastVersionCheck) {
        const lastCheckTime = parseInt(lastVersionCheck);
        const sixHoursInMs = 6 * 60 * 60 * 1000;
        if (Date.now() - lastCheckTime < sixHoursInMs) {
          return;
        }
      }

      // Check if the app has been reloaded recently to avoid frequent reloads - 1hr
      if (lastAppReload) {
        const lastReloadTime = parseInt(lastAppReload);
        const oneHourInMs = 60 * 60 * 1000;
        if (Date.now() - lastReloadTime < oneHourInMs) {
          return;
        }
      }

      const res: { version: string } = await get(ROUTES.APP_VERSION);

      localStorage.setItem('last-version-check', Date.now().toString());

      const jsVersion = packageJson.version;
      const apiVersion = res.version;

      const reloadApp = compareVersions(jsVersion, apiVersion) === -1;

      if (reloadApp) {
        localStorage.setItem('last-app-reload', Date.now().toString());
        Mixpanel.track(EventNames.APP_RELOAD, {
          'Current Version': packageJson.version,
          'New Version': res.version,
        });
        hardReload();
      }
    } catch (err) {
      console.log('Version Err: ', err);
    }
  }

  const handleFocus = () => {
    fetchAppVersion();

    // Remove last-error-time if it has been 10 minutes
    const lastErrorTime = localStorage.getItem('last-error-time');
    if (lastErrorTime) {
      const tenMinutesInMs = 10 * 60 * 1000;
      if (Date.now() - parseInt(lastErrorTime) > tenMinutesInMs) {
        localStorage.removeItem('last-error-time');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);


  if (isUserDetailsLoading) {
    return <Loader />;
  }

  return (
    //@ts-ignore
    <HistoryRouter history={history}>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/all-meetings"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <UpcomingMeetings />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/past-meetings"
          element={
            <Layout>
              <div />
            </Layout>
          }
        />
        <Route
          path="/all-clients"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <AllClients />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/client/:clientId"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <ClientAllMeetings />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/meeting-details/:meetingId"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <MeetingDetails />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/customize-notes"
          element={
            <Layout>
              <div />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout>
              <Settings />
            </Layout>
          }
        />
        <Route
          path="*"
          element={<Navigate to="/all-meetings" replace />}
        />
      </Routes>
    </HistoryRouter>
  );
};

export default Router;
