import { createBrowserHistory, Location } from "history";

const history = createBrowserHistory();
let historyStack: Location[] = [history.location];
let lastRoute = "Direct";

history.listen(({ location, action }) => {
  historyStack.push(location);

  try {
    lastRoute = historyStack[historyStack.length - 2]?.pathname;
  } catch (err) {
    console.log("historyStack", err);
  }
});

function resetHistory() {
  historyStack = [history.location];
  lastRoute = "Direct";
}

export { historyStack, lastRoute, resetHistory };
export default history;
